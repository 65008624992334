import { TitleArea } from 'components/TitleArea/TitleArea';
import { Box } from '@mui/material';
import ProductIcon from 'components/ProductIcon';
import PageTitle from 'components/PageTitle';
import { CONSTITUENT_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { NAVDetailsCard } from './Cards/NAVDetailsCard/NAVDetailsCard';
import { Button } from 'components/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import useAppModal from 'hooks/useAppModal';
import { DateValue } from 'components/DatepickerSingleDate';
import { PortfolioCompositionStatus } from 'utils/types/pcfs';
import { format } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'utils/date';
import Notification from 'components/Notification';
import { NotificationType } from 'utils/types';
import { useMemo, useState } from 'react';
import { NAVDetailsWithProduct, NAVNotificationDataInterface } from './utils/types';
import { CustodyCard } from './Cards/CustodyCard/CustodyCard';
import { ReconciliationCard } from './Cards/ReconciliationCard/ReconciliationCard';
import { useNAVReviewItemDetailsQuery } from './hooks/useNavReviewItemDetailsQuery';
import { NAVDetailsPageHeader } from './NavDetailsPageHeader';
import Column from 'components/Grid/Column';
import { downloadNAVCsv } from 'utils/nav/NAVCsv';
import { useUserPermissions } from 'store/user/selectors';
import Loader from 'components/Loader';
import { NAVNotificationData } from './utils/notificationsData';
import isEmpty from 'lodash/isEmpty';

export const NAVDetailsPage = () => {
  const params = useParams();
  const userPermissions = useUserPermissions();
  const navigate = useNavigate();
  const openModal = useAppModal();
  const constituentTicker = params.constituentTicker ?? '';
  const productId = params.productId ?? '';
  const valuationDate = params.valuationDate ?? '';
  const [notificationData, setNotificationData] = useState<NAVNotificationDataInterface | null>(
    null
  );

  const {
    reviewNAVItemOldValue,
    mappedNavDetailsTransactions,
    navReviewItem,
    updateDeltaNavReviewItem,
    isLoading,
    calculateNavAndRefetchMutation,
  } = useNAVReviewItemDetailsQuery({
    constituentTicker: constituentTicker,
    valuationdate: valuationDate,
    productId: productId,
  });

  const handleDateChange = (date: DateValue) => {
    const formattedDate = date && format(date, DEFAULT_DATE_FORMAT);
    navigate(
      `/nav/pcf/${productId}/nav/constituent/${constituentTicker}/valuationDate/${formattedDate}`
    );
  };

  const openEditConstituentModal = (type: MODAL_ACTIONS, constituentTicker?: string) => {
    const constituent = navReviewItem?.product?.constituentAssets?.find(
      (constituenAsset) => constituenAsset.ticker === constituentTicker
    );
    openModal(
      {
        modalName: CONSTITUENT_MODAL,
        modalData: {
          data: {
            constituent: constituent,
            constituents: navReviewItem?.product?.constituentAssets,
          },
          custom: {
            productId,
          },
          type: type,
        },
      },
      {
        onCloseModalAction: calculateNavAndRefetchMutation.mutate,
      }
    );
  };

  const hasOpenOrders =
    navReviewItem?.openOrders !== undefined && !isEmpty(navReviewItem?.openOrders);
  const rebalanceNotApproved = navReviewItem?.isRebalanceApproved === false;

  useMemo(() => {
    setNotificationData(
      NAVNotificationData(
        navReviewItem as NAVDetailsWithProduct,
        rebalanceNotApproved,
        hasOpenOrders,
        navigate
      )
    );
  }, [navReviewItem]);

  const ActionButton = (
    <Button
      variant="inverse"
      onClick={() => openEditConstituentModal(MODAL_ACTIONS.CUSTOM, navReviewItem?.ticker)}
    >
      Edit Constituent
    </Button>
  );

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <TitleArea
        title={
          navReviewItem ? (
            <Box sx={{ display: 'flex', gap: '8px' }}>
              <ProductIcon
                className="product-icon"
                iconUrl={navReviewItem?.product?.fundIcon}
                ticker={navReviewItem?.product?.ticker}
                size="40px"
              />
              <PageTitle>{navReviewItem?.product?.ticker} NAV Details</PageTitle>
            </Box>
          ) : null
        }
        showBackButton={true}
        actionButton={ActionButton}
      />

      <>
        {notificationData && (
          <Column gutter="noGutter" p={0}>
            <Notification
              action={notificationData.action}
              actionText={notificationData.actionText}
              title={notificationData.title ?? ''}
              message={notificationData.message ?? ''}
              variant={notificationData.variant as NotificationType}
              messageMaxWidth
              multiline={'false'}
              withcountdown="false"
            />
          </Column>
        )}
        <NAVDetailsPageHeader
          handleDateChange={handleDateChange}
          navReviewDate={valuationDate ?? ''}
          onRefreshData={
            navReviewItem?.status !== PortfolioCompositionStatus.APPROVED
              ? calculateNavAndRefetchMutation.mutate
              : undefined
          }
          downloadCsv={() =>
            downloadNAVCsv(
              navReviewItem,
              valuationDate,
              mappedNavDetailsTransactions,
              userPermissions
            )
          }
        />
        <ReconciliationCard navReviewItem={navReviewItem as NAVDetailsWithProduct} />
        {navReviewItem && mappedNavDetailsTransactions && (
          <NAVDetailsCard
            reviewNAVItemOldValue={reviewNAVItemOldValue}
            navReviewItem={navReviewItem as NAVDetailsWithProduct}
            navTransactions={mappedNavDetailsTransactions}
            updateDeltaNavReviewItem={updateDeltaNavReviewItem}
            userPermissions={userPermissions}
            refreshData={() => {
              calculateNavAndRefetchMutation.mutate();
            }}
          />
        )}
        <CustodyCard navReviewItem={navReviewItem as NAVDetailsWithProduct} />
      </>
    </>
  );
};
